import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'
import {makeStyles} from '@material-ui/core/styles'
import {
  getDelivery,
  clearCurrentDelivery,
  setOrders,
  duplicateDelivery,
  startLoading,
  stopLoading,
} from '../../redux/actions'
import {apiFormatDate, formatDate, generalFormatDate} from '../../utils/dates'
import {formatName} from '../../utils/common'
import DatePicker from '../../components/DatePicker'
import Table from '../../components/Table'
import {
  Container,
  TopContainer,
  HeaderTypography,
  DeliveryDetails,
  DeliveryDetailsContainer,
  DeliveryActions,
} from './styles'
import BackButton from '../../components/BackButton'
import {PrimaryButton} from '../../components/Button'
import Loading from '../../components/LoadingOrange'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginTop: '1.6rem',
    marginBottom: '1.2rem',
  },
  topContainer: {
    textAlign: 'center',
  },
  label: {
    fontWeight: 500,
  },
  addressText: {
    marginTop: '26px',
  },
  addOrderButton: {
    alignSelf: 'center',
    width: '16.5rem',
  },
  nextButton: {
    maxWidth: '14rem',
    margin: '2.8rem 0 2.8rem 0',
    alignSelf: 'flex-end',
  },
  addReturnButton: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    alignSelf: 'center',
    transition: 'none',
    width: '18rem',
    marginRight: '1.2rem',
    textTransform: 'capitalize',
    '&[disabled]': {
      color: theme.buttons.disabled.label,
    },
  },
}))

const DuplicateDelivery = ({
  rootPath,
  orders = [],
  clearCurrentDelivery,
  getDelivery,
  setOrders,
  duplicateDelivery,
  loading,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const {deliveryId} = useParams()
  const [date, setSelectedDate] = useState(generalFormatDate(new Date()))
  const [displayDate, setDisplayDate] = useState(formatDate(new Date()))
  const [selectedOrders, setSelectedOrders] = useState([])
  const [sortConfig, setSortConfig] = useState({
    property: 'orderId',
    direction: 'desc'
  });

  useEffect(() => {
    if (deliveryId) getDelivery(deliveryId)
  }, [deliveryId, getDelivery])

  useEffect(() => {
    if (orders.length > 0) {
      setSelectedOrders(orders.map((order) => order.orderId))
    }
  }, [orders])

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    setDisplayDate(formatDate(newDate))
  }

  const redirectToHome = () => {
    history.push(rootPath)
    clearCurrentDelivery()
  }

  const getSortableValue = (order, property) => {
    if (property === 'customerName') {
      return formatName(order.customerName || '').toLowerCase().trim();
    }
    if (property === 'customerPhone') {
      return (order.customerPhone || '').replace(/\D/g, '');
    }
    if (property === 'orderId') {
      return order.orderId;
    }
    return String(get(order, property, '')).toLowerCase();
  };

  const getSortedOrders = () => {
    return orderBy(
      orders,
      [order => getSortableValue(order, sortConfig.property)],
      [sortConfig.direction]
    );
  };

  const sortOrders = (property, direction) => {
    if (property === sortConfig.property) {
      if (sortConfig.direction === 'asc') {
        setSortConfig({ property, direction: 'desc' });
        const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['desc']);
        setOrders(sorted);
      } 
      else {
        setSortConfig({ property, direction: 'asc' });
        const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['asc']);
        setOrders(sorted);
      }
    } 
    else {
      setSortConfig({ property, direction: 'asc' });
      const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['asc']);
      setOrders(sorted);
    }
  };

  const sortedOrders = getSortedOrders();

  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId],
    )
    console.log('Selected Orders:', selectedOrders)
  }

  const handleDuplicateDelivery = () => {
    const selectedOrdersData = orders
      .filter((order) => selectedOrders.includes(order.orderId))
      .map((order) => order.orderId)
    duplicateDelivery(history, {
      deliveryDate: apiFormatDate(date),
      deliveryId: parseInt(deliveryId),
      orders: selectedOrdersData,
    })
  }

  return (
    <>
      <Container>
        <TopContainer className={classes.topContainer}>
          <BackButton click={redirectToHome} />
          <div>
            <HeaderTypography variant="h1">Duplicate Delivery</HeaderTypography>
            <div>{displayDate}</div>
          </div>
          <div></div>
        </TopContainer>

        <DeliveryDetailsContainer>
          <DeliveryDetails>
            <DatePicker
              label="Select Delivery Date"
              name="deliveryDate"
              date={date}
              value={date}
              onChange={handleDateChange}
              autoOk
            />
          </DeliveryDetails>
          <DeliveryActions style={{justifyContent: 'start', width: '171px'}}>
            <PrimaryButton onClick={handleDuplicateDelivery}>
              Duplicate
            </PrimaryButton>
          </DeliveryActions>
        </DeliveryDetailsContainer>
        <Table
          rows={sortedOrders}
          keyProp="orderId"
          headers={[
            {
              label: 'Name',
              property: 'customerName',
              onClick: sortOrders,
              sortActive: sortConfig.property === 'customerName',
              sortDirection:
                sortConfig.property === 'customerName'
                  ? sortConfig.direction
                  : null,
            },
            {label: 'Address'},
            {
              label: 'Phone',
              property: 'customerPhone',
              onClick: sortOrders,
              sortActive: sortConfig.property === 'customerPhone',
              sortDirection:
                sortConfig.property === 'customerPhone'
                  ? sortConfig.direction
                  : null,
            },
            {label: 'TX Number / Price'},
            {label: 'Customer Payment'},
            {label: 'Select'},
          ]}
          properties={[
            'customerName',
            'customerAddress',
            'customerPhone',
            'detail',
            'paymentType',
            'select',
          ]}
          handleCheckboxChange={handleCheckboxChange}
          selectedOrders={selectedOrders}
        />
      </Container>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({user = {}, deliveries = [], app: {loading}}) => {
  return {
    delivery: deliveries.selectedDelivery,
    orders: get(deliveries, 'selectedDelivery.orders', []),
    token: user.token,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
  clearCurrentDelivery: () => dispatch(clearCurrentDelivery()),
  setOrders: (orders) => dispatch(setOrders(orders)),
  duplicateDelivery: (history, payload) =>
    dispatch(duplicateDelivery(history, payload)),
  startLoading: () => dispatch(startLoading()),
  stopLoading: () => dispatch(stopLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateDelivery)
