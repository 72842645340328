import {put, throttle, takeLatest, select} from 'redux-saga/effects'
import {getToken} from '../utils'
import {
  GET_STORE,
  GET_INTERCOM_HASH,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'
import {
  getStoreInfoSuccess,
  getIntercomHashSuccess,
  showError,
} from '../actions'

import {
  API_STORE_ENDPOINT,
  API_INTERCOM_HASH_ENDPOINT,
  API_THROTTLE_TIME_MS,
} from '../../constants'
import {request} from '../../utils/api'

export function* getStoreInfo({payload}) {
  const reqOpt = {
    method: 'GET',
    url: API_STORE_ENDPOINT,
    token: payload,
  }
  try {
    yield put({type: START_LOADING})
    const storeInfo = yield request(reqOpt)
    yield put(getStoreInfoSuccess(storeInfo))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* getIntercomIdentityHash({payload}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'POST',
    url: API_INTERCOM_HASH_ENDPOINT,
    token,
    body: JSON.stringify({userEmail: payload}),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const response = yield request(reqOpt)
    yield put(getIntercomHashSuccess(response.hash))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchUsers() {
  yield throttle(API_THROTTLE_TIME_MS, GET_STORE, getStoreInfo)
  yield takeLatest(GET_INTERCOM_HASH, getIntercomIdentityHash)
}

export default watchUsers
