import React from 'react'
import styled from 'styled-components'

export const SummarySection = ({
  storeReportsStatuses,
  qbInvoiceStatuses,
  emailQueueStatuses,
}) => {
  const calculateSummary = () => {
    const summary = {
      reports: 0,
      cashReports: 0,
      regularInvoices: 0,
      cashInvoices: 0,
      emailsSent: 0,
      cashEmails: 0,
    }

    if (storeReportsStatuses?.length) {
      summary.reports = storeReportsStatuses.filter(
        (status) => status.status === 'completed' && status.progress === 100,
      ).length

      summary.cashReports = storeReportsStatuses.filter(
        (status) =>
          status.status === 'completed' &&
          status.progress === 100 &&
          status.isCash === true,
      ).length
    }

    if (qbInvoiceStatuses?.length) {
      qbInvoiceStatuses.forEach((status) => {
        if (status.status === 'completed' && status.progress === 100) {
          if (status.invoiceId) summary.regularInvoices++
          if (status.cashInvoiceId) summary.cashInvoices++
        }
      })
    }

    if (emailQueueStatuses?.length) {
      summary.emailsSent = emailQueueStatuses.filter(
        (status) => status.status === 'completed' && status.progress === 100,
      ).length

      summary.cashEmails = emailQueueStatuses.filter(
        (status) =>
          status.status === 'completed' &&
          status.progress === 100 &&
          status.isCash === true,
      ).length
    }

    return summary
  }

  const summary = calculateSummary()

  return (
    <SummaryContainer>
      <SummaryTitle>Reports Summary</SummaryTitle>
      <SummaryGrid>
        <SummaryCard>
          <SummaryValue>{summary.reports}</SummaryValue>
          <SummaryLabel>Reports Generated</SummaryLabel>
        </SummaryCard>
        <SummaryCard>
          <SummaryValue>{summary.regularInvoices}</SummaryValue>
          <SummaryLabel>Regular Invoices Created</SummaryLabel>
        </SummaryCard>
        <SummaryCard>
          <SummaryValue>{summary.emailsSent}</SummaryValue>
          <SummaryLabel>Emails Sent</SummaryLabel>
        </SummaryCard>
        <SummaryCard>
          <SummaryValue>{summary.cashReports}</SummaryValue>
          <SummaryLabel>Cash Reports Generated</SummaryLabel>
        </SummaryCard>
        <SummaryCard>
          <SummaryValue>{summary.cashInvoices}</SummaryValue>
          <SummaryLabel>Cash Invoices Created</SummaryLabel>
        </SummaryCard>
        <SummaryCard>
          <SummaryValue>{summary.cashEmails}</SummaryValue>
          <SummaryLabel>Cash Emails Sent</SummaryLabel>
        </SummaryCard>
      </SummaryGrid>
    </SummaryContainer>
  )
}

export const SummaryContainer = styled.div`
  margin-bottom: 25px;
  padding: 20px;
`

export const SummaryTitle = styled.h2`
  font-size: 24px;
  margin: 0 0 20px 0;
  color: ${({theme}) => theme.colors.text};
  text-align: center;
`

export const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`

export const SummaryCard = styled.div`
  background: ${({theme}) => `${theme.colors.primary}10`};
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SummaryValue = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary};
  margin-bottom: 8px;
`

export const SummaryLabel = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.text};
`
