import {
  put,
  takeLatest,
  select,
  call,
  delay,
  race,
  take,
} from 'redux-saga/effects'
import {toast} from '../../components/ToastMessage'

import {
  CREATE_PHARMACY,
  START_LOADING,
  STOP_LOADING,
  FETCH_DRIVERS,
  FETCH_STORES,
  ADMIN_FETCH_STORE,
  EDIT_ADMIN_FETCH_STORE,
  ADD_DRIVER,
  DELETE_DRIVERS,
  GENERATE_STORE_REPORTS,
  GET_STORE_REPORTS_STATUS,
  STOP_STORE_REPORTS_POLLING,
  CONNECT_QUICKBOOKS,
  HANDLE_QUICKBOOKS_CALLBACK,
  GENERATE_QB_INVOICES,
  GET_QB_INVOICES_STATUS,
  STOP_QB_INVOICES_POLLING,
  GENERATE_EMAIL_QUEUE,
  GENERATE_EMAIL_QUEUE_SUCCESS,
  GET_EMAIL_QUEUE_STATUS,
  GET_EMAIL_QUEUE_STATUS_SUCCESS,
  STOP_EMAIL_QUEUE_POLLING,
  CLEAR_EMAIL_QUEUE,
  CLEAR_REDIS,
} from '../actions/types'
import {showError} from '../actions'

import {
  API_STORE_ENDPOINT,
  API_REPORTS_ENDPOINT,
  API_DRIVERS_ENDPOINT,
  API_EXPORT_STORE_REPORTS_ENDPOINT,
  API_GET_STORE_REPORTS_STATUS_ENDPOINT,
  API_QUICKBOOKS_CONNECT_ENDPOINT,
  API_QUICKBOOKS_CALLBACK_ENDPOINT,
  API_QUICKBOOKS_GENERATE_INVOICES_ENDPOINT,
  API_QUICKBOOKS_INVOICES_STATUS_ENDPOINT,
  API_GMAIL_SEND_INVOICES_ENDPOINT,
  API_GMAIL_STATUS_ENDPOINT,
  API_REDIS_CLEAR_ENDPOINT,
} from '../../constants'
import {request} from '../../utils/api'
import {getToken} from '../utils'

import {
  fetchDriversSuccess,
  fetchStoresSuccess,
  addDriverSuccess,
  deleteDriversSuccess,
  generateStoreReportsSuccess,
  getStoreReportsStatusesSuccess,
  quickbooksConnectionSuccess,
  generateQBInvoicesSuccess,
  getQBInvoicesStatusSuccess,
  generateEmailQueueSuccess,
  getEmailQueueStatusSuccess,
} from '../actions'

export function* createPharmacy({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_STORE_ENDPOINT,
    token,
    body: payload.data,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    const store = yield request(reqOpt)
    yield put({type: STOP_LOADING})
    toast.success(`Successfully created store "${store.storeNumber}"`)
    payload.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

export function* fetchDrivers({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_DRIVERS_ENDPOINT,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const drivers = yield request(reqOpt)
    yield put(fetchDriversSuccess(drivers))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* fetchStores({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_REPORTS_ENDPOINT}/stores`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const stores = yield request(reqOpt)
    yield put(fetchStoresSuccess(stores))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
  }
}

export function* adminFetchStore({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_STORE_ENDPOINT}/${payload.data.storeId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const adminStoreInfo = yield request(reqOpt)
    payload.data.callback(adminStoreInfo)
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* editAdminFetchStore({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_STORE_ENDPOINT}`,
    token,
    body: payload.data.data,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({type: STOP_LOADING})
    toast.success(
      `Successfully edited store "${payload.data.data.storeNumber}"`,
    )
    payload.data.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

export function* addDriver({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_DRIVERS_ENDPOINT,
    token,
    body: payload,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }

  try {
    yield put({type: START_LOADING})
    const driver = yield request(reqOpt)
    yield put(addDriverSuccess(driver))
    toast.success(`Successfully added driver ${driver.driverName}`)
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

export function* deleteDrivers({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'DELETE',
    url: API_DRIVERS_ENDPOINT,
    token,
    body: {
      ids: payload,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    const results = yield request(reqOpt)

    yield put(deleteDriversSuccess(results))
    yield put({type: STOP_LOADING})

    const {successful} = results.results

    for (const driver of successful) {
      toast.success(`Successfully deleted driver ${driver.name}`)
    }
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* connectQuickbooks() {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_QUICKBOOKS_CONNECT_ENDPOINT,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)

    if (result.authUri) {
      window.location.href = result.authUri
    }
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error('Failed to connect to QuickBooks: ' + error.message)
  }
}

export function* generateStoreReports({payload}) {
  const {storeIds, dateRange} = payload
  const token = yield select(getToken)

  const encodedStartDate = encodeURIComponent(dateRange.startDate)
  const encodedEndDate = encodeURIComponent(dateRange.endDate)

  const reqOpt = {
    method: 'GET',
    url: `${API_EXPORT_STORE_REPORTS_ENDPOINT}/${storeIds.join(
      ',',
    )}?startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)
    yield put(generateStoreReportsSuccess({...result, dateRange}))
    yield put({type: STOP_LOADING})
    toast.info('Generating reports...')
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

function* fetchStoreReportsStatus(payload) {
  try {
    const token = yield select(getToken)
    const dateRange = yield select((state) => state.admin.dateRange)

    const reqOpt = {
      method: 'GET',
      url: `${API_GET_STORE_REPORTS_STATUS_ENDPOINT}/${payload.join(',')}`,
      token,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const result = yield request(reqOpt)
    yield put(getStoreReportsStatusesSuccess({jobs: result.jobs, dateRange}))
  } catch (error) {
    yield put({type: STOP_STORE_REPORTS_POLLING})
    toast.error(error.message)
  }
}

function* pollStoreReportsStatus(payload) {
  while (true) {
    const storeReportsQueue = yield select(
      (state) => state.admin.storeReportsQueue,
    )

    if (storeReportsQueue.length === 0) {
      yield put({type: STOP_STORE_REPORTS_POLLING})
      break
    }

    yield call(fetchStoreReportsStatus, payload)
    const statuses = yield select((state) => state.admin.storeReportsStatuses)

    const allComplete = statuses.every(
      (status) => status.status === 'completed',
    )

    if (allComplete) {
      yield put({type: STOP_STORE_REPORTS_POLLING})
      break
    }

    yield delay(2000)
  }
}

export function* getStoreReportsStatus({payload}) {
  yield race({
    task: call(pollStoreReportsStatus, payload),
    cancel: take(STOP_STORE_REPORTS_POLLING),
  })
}

export function* handleQuickbooksCallback({payload}) {
  const token = yield select(getToken)
  const {code, realmId, state} = payload
  const reqOpt = {
    method: 'GET',
    url: `${API_QUICKBOOKS_CALLBACK_ENDPOINT}?code=${code}&realmId=${realmId}&state=${state}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)
    yield put(quickbooksConnectionSuccess(result.token))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error('Failed to complete QuickBooks connection: ' + error.message)
  }
}

export function* generateQBInvoices({payload}) {
  const token = yield select(getToken)
  const dateRange = yield select((state) => state.admin.dateRange)

  if (!dateRange) {
    toast.error('Please select a date range first')
    return
  }

  if (!payload || !payload.length) {
    toast.error('Please select at least one store')
    return
  }

  const reqOpt = {
    method: 'POST',
    url: API_QUICKBOOKS_GENERATE_INVOICES_ENDPOINT,
    token,
    body: {
      storeIds: payload,
      dateRange,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)
    yield put(generateQBInvoicesSuccess(result.jobIds))
    yield put({type: STOP_LOADING})
    toast.info('Generating QuickBooks invoices...')
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(
      'Failed to start QuickBooks invoice generation: ' + error.message,
    )
  }
}

function* getQBInvoicesStatus(jobIds) {
  try {
    const token = yield select(getToken)
    const reqOpt = {
      method: 'GET',
      url: `${API_QUICKBOOKS_INVOICES_STATUS_ENDPOINT}/${jobIds.join(',')}`,
      token,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const result = yield request(reqOpt)

    yield put(getQBInvoicesStatusSuccess(result))
  } catch (error) {
    yield put({type: STOP_QB_INVOICES_POLLING})
    toast.error(error.message)
  }
}

function* pollQBInvoicesStatus(payload) {
  while (true) {
    const qbInvoicesQueue = yield select((state) => state.admin.qbInvoicesQueue)

    if (qbInvoicesQueue.length === 0) {
      yield put({type: STOP_QB_INVOICES_POLLING})
      break
    }

    yield call(getQBInvoicesStatus, payload)

    const statuses = yield select(
      (state) => state.admin.qbInvoiceStatuses || [],
    )

    const allComplete = statuses.every(
      (status) => status.status === 'completed' || status.status === 'failed',
    )

    if (allComplete) {
      const hasFailures = statuses.some((status) => status.status === 'failed')
      if (hasFailures) {
        toast.error('Some QuickBooks invoices failed to generate')
      } else {
        toast.success('All QuickBooks invoices generated successfully')
      }
      yield put({type: STOP_QB_INVOICES_POLLING})
      break
    }

    yield delay(2000)
  }
}

export function* watchQBInvoicesStatus({payload}) {
  yield race({
    task: call(pollQBInvoicesStatus, payload),
    cancel: take(STOP_QB_INVOICES_POLLING),
  })
}

function* generateEmailQueue({payload}) {
  const token = yield select(getToken)
  const dateRange = yield select((state) => state.admin.dateRange)

  if (!dateRange) {
    toast.error('Please select a date range first')
    return
  }

  if (!payload.length) {
    toast.error('Please select at least one store')
    return
  }

  const reqOpt = {
    method: 'POST',
    url: API_GMAIL_SEND_INVOICES_ENDPOINT,
    token,
    body: {
      stores: payload,
      dateRange,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }

  try {
    yield put({type: START_LOADING})
    const result = yield request(reqOpt)
    yield put(generateEmailQueueSuccess(result.jobIds))
    yield put({type: STOP_LOADING})
    toast.info('Sending emails...')
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error('Failed to start sending emails')
  }
}

function* getEmailQueueStatus(jobIds) {
  try {
    const token = yield select(getToken)
    const reqOpt = {
      method: 'GET',
      url: `${API_GMAIL_STATUS_ENDPOINT}/${jobIds.join(',')}`,
      token,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const result = yield request(reqOpt)
    yield put(getEmailQueueStatusSuccess(result))
  } catch (error) {
    yield put({type: STOP_EMAIL_QUEUE_POLLING})
    toast.error(error.message)
  }
}

function* pollEmailQueueStatus(payload) {
  while (true) {
    const emailQueue = yield select((state) => state.admin.emailQueue)

    if (emailQueue.length === 0) {
      yield put({type: STOP_EMAIL_QUEUE_POLLING})
      break
    }

    yield call(getEmailQueueStatus, payload)
    const statuses = yield select(
      (state) => state.admin.emailQueueStatuses || [],
    )

    const allComplete = statuses.every(
      (status) => status.status === 'completed' || status.status === 'failed',
    )

    if (allComplete) {
      const hasFailures = statuses.some((status) => status.status === 'failed')
      if (hasFailures) {
        toast.error('Some emails failed to send')
      } else {
        toast.success('All emails sent successfully')
      }
      yield put({type: STOP_EMAIL_QUEUE_POLLING})
      break
    }

    yield delay(2000)
  }
}

export function* watchEmailQueueStatus({payload}) {
  yield race({
    task: call(pollEmailQueueStatus, payload),
    cancel: take(STOP_EMAIL_QUEUE_POLLING),
  })
}

export function* clearRedis() {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'POST',
    url: API_REDIS_CLEAR_ENDPOINT,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
    stringify: true,
  }

  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

export function* watchAdmin() {
  yield takeLatest(CREATE_PHARMACY, createPharmacy)
  yield takeLatest(FETCH_DRIVERS, fetchDrivers)
  yield takeLatest(FETCH_STORES, fetchStores)
  yield takeLatest(ADMIN_FETCH_STORE, adminFetchStore)
  yield takeLatest(EDIT_ADMIN_FETCH_STORE, editAdminFetchStore)
  yield takeLatest(ADD_DRIVER, addDriver)
  yield takeLatest(DELETE_DRIVERS, deleteDrivers)
  yield takeLatest(GENERATE_STORE_REPORTS, generateStoreReports)
  yield takeLatest(GET_STORE_REPORTS_STATUS, getStoreReportsStatus)
  yield takeLatest(CONNECT_QUICKBOOKS, connectQuickbooks)
  yield takeLatest(HANDLE_QUICKBOOKS_CALLBACK, handleQuickbooksCallback)
  yield takeLatest(GENERATE_QB_INVOICES, generateQBInvoices)
  yield takeLatest(GET_QB_INVOICES_STATUS, watchQBInvoicesStatus)
  yield takeLatest(GENERATE_EMAIL_QUEUE, generateEmailQueue)
  yield takeLatest(GET_EMAIL_QUEUE_STATUS, watchEmailQueueStatus)
  yield takeLatest(CLEAR_REDIS, clearRedis)
}

export default watchAdmin
