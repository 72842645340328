import {
  FETCH_DRIVERS_SUCCESS,
  FETCH_STORES_SUCCESS,
  DOWNLOAD_STORE_REPORT,
  DOWNLOAD_DRIVER_REPORT,
  STOP_LOADING,
  ADD_DRIVER_SUCCESS,
  DELETE_DRIVERS_SUCCESS,
  GET_STORE_REPORTS_STATUS_SUCCESS,
  STOP_STORE_REPORTS_POLLING,
  GENERATE_STORE_REPORTS_SUCCESS,
  CLEAR_STORE_REPORTS_QUEUE,
  QUICKBOOKS_CONNECTION_SUCCESS,
  GENERATE_QB_INVOICES_SUCCESS,
  GET_QB_INVOICES_STATUS_SUCCESS,
  STOP_QB_INVOICES_POLLING,
  CLEAR_QB_INVOICES_QUEUE,
  GENERATE_EMAIL_QUEUE_SUCCESS,
  GET_EMAIL_QUEUE_STATUS_SUCCESS,
  STOP_EMAIL_QUEUE_POLLING,
  CLEAR_EMAIL_QUEUE,
} from '../actions/types'

const initialState = {
  drivers: [],
  stores: [],
  downloadingReport: false,
  storeReportsStatuses: [],
  storeReportsQueue: [],
  quickbooksToken: null,
  dateRange: null,
  qbInvoicesQueue: [],
  qbInvoiceStatuses: [],
  emailQueue: [],
  emailQueueStatuses: [],
}

function adminReducers(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
      }
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      }
    case DOWNLOAD_STORE_REPORT:
    case DOWNLOAD_DRIVER_REPORT:
      return {
        ...state,
        downloadingReport: true,
      }
    case STOP_LOADING:
      return {
        ...state,
        downloadingReport: false,
      }
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: [action.payload, ...state.drivers],
      }
    case DELETE_DRIVERS_SUCCESS:
      const successfullyDeletedIds = action.payload.results.successful.map(
        (driver) => driver.id,
      )
      return {
        ...state,
        drivers: state.drivers.filter(
          (driver) => !successfullyDeletedIds.includes(driver.driverId),
        ),
      }
    case GET_STORE_REPORTS_STATUS_SUCCESS:
      return {
        ...state,
        storeReportsStatuses: action.payload.jobs.map((job) => ({
          ...job,
          dateRange: action.payload.dateRange,
        })),
      }
    case STOP_STORE_REPORTS_POLLING:
      return {
        ...state,
        storeReportsQueue: [],
      }
    case GENERATE_STORE_REPORTS_SUCCESS:
      return {
        ...state,
        storeReportsQueue: [
          ...state.storeReportsQueue,
          ...action.payload.jobIds,
        ],
        dateRange: action.payload.dateRange,
      }
    case CLEAR_STORE_REPORTS_QUEUE:
      return {
        ...state,
        storeReportsQueue: [],
        storeReportsStatuses: [],
        dateRange: null,
      }
    case QUICKBOOKS_CONNECTION_SUCCESS:
      return {
        ...state,
        quickbooksToken: action.payload.token,
      }
    case GENERATE_QB_INVOICES_SUCCESS:
      return {
        ...state,
        qbInvoicesQueue: action.payload,
      }
    case GET_QB_INVOICES_STATUS_SUCCESS:
      return {
        ...state,
        qbInvoiceStatuses: action.payload.jobs,
      }
    case STOP_QB_INVOICES_POLLING:
      return {
        ...state,
        qbInvoicesQueue: [],
      }
    case CLEAR_QB_INVOICES_QUEUE:
      return {
        ...state,
        qbInvoicesQueue: [],
        qbInvoiceStatuses: [],
      }
    case GENERATE_EMAIL_QUEUE_SUCCESS:
      return {
        ...state,
        emailQueue: action.payload,
      }
    case GET_EMAIL_QUEUE_STATUS_SUCCESS:
      return {
        ...state,
        emailQueueStatuses: action.payload.jobs,
      }
    case STOP_EMAIL_QUEUE_POLLING:
      return {
        ...state,
        emailQueue: [],
      }
    case CLEAR_EMAIL_QUEUE:
      return {
        ...state,
        emailQueue: [],
        emailQueueStatuses: [],
      }
    default:
      return state
  }
}

export default adminReducers
