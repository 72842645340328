import React from 'react'
import styled from 'styled-components'
import {InputLabel} from '@material-ui/core'
import {PrimaryButton} from '../../../components/Button'

export const WeeklyScheduleForm = ({weeklySchedule, setWeeklySchedule}) => {
  const days = [
    {id: 'monday', label: 'Monday'},
    {id: 'tuesday', label: 'Tuesday'},
    {id: 'wednesday', label: 'Wednesday'},
    {id: 'thursday', label: 'Thursday'},
    {id: 'friday', label: 'Friday'},
    {id: 'saturday', label: 'Saturday'},
    {id: 'sunday', label: 'Sunday'},
  ]

  const timeOptions = () => {
    const options = [
      <option key="no-delivery" value="NO_DELIVERIES">
        NO DELIVERIES
      </option>,
    ]

    for (let hour = 7; hour <= 23; hour++) {
      const hourLabel = hour > 12 ? hour - 12 : hour
      const amPm = hour >= 12 ? 'pm' : 'am'

      options.push(
        <option key={`${hour}:00`} value={`${hour}:00`}>
          {`${hourLabel}:00 ${amPm}`}
        </option>,
      )

      if (hour < 23) {
        options.push(
          <option key={`${hour}:30`} value={`${hour}:30`}>
            {`${hourLabel}:30 ${amPm}`}
          </option>,
        )
      }
    }

    return options
  }

  const handleTimeChange = (day, slotIndex, value) => {
    setWeeklySchedule((prev) => ({
      ...prev,
      [day]: prev[day].map((slot, idx) => (idx === slotIndex ? value : slot)),
    }))
  }

  const handleClearSchedule = () => {
    const emptySchedule = {
      monday: ['', ''],
      tuesday: ['', ''],
      wednesday: ['', ''],
      thursday: ['', ''],
      friday: ['', ''],
      saturday: ['', ''],
      sunday: ['', ''],
    }
    setWeeklySchedule(emptySchedule)
  }

  return (
    <ScheduleContainer>
      <LabelContainer>
        <StyledLabel>Weekly Delivery Schedule</StyledLabel>
        <ClearButton onClick={handleClearSchedule}>Clear Schedule</ClearButton>
      </LabelContainer>
      <ScheduleTable>
        <thead>
          <tr>
            <th>Day</th>
            <th>Delivery Time 1</th>
            <th>Delivery Time 2</th>
          </tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <tr key={day.id}>
              <td>{day.label}</td>
              <td>
                <TimeSelect
                  value={weeklySchedule[day.id][0]}
                  onChange={(e) => handleTimeChange(day.id, 0, e.target.value)}>
                  <option value="">Select Time</option>
                  {timeOptions()}
                </TimeSelect>
              </td>
              <td>
                <TimeSelect
                  value={weeklySchedule[day.id][1]}
                  onChange={(e) => handleTimeChange(day.id, 1, e.target.value)}
                  disabled={weeklySchedule[day.id][0] === 'NO_DELIVERIES'}>
                  <option value="">Select Time</option>
                  {timeOptions()}
                </TimeSelect>
              </td>
            </tr>
          ))}
        </tbody>
      </ScheduleTable>
      <HelpText>
        * Select time slots for each day or "NO DELIVERIES" if there are no
        deliveries on a particular day. * When "NO DELIVERIES" is selected, the
        second time slot will be disabled.
      </HelpText>
    </ScheduleContainer>
  )
}

const ScheduleContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`

const StyledLabel = styled(InputLabel)`
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 15px;
`

const ClearButton = styled(PrimaryButton)`
  padding: 15px;
  height: auto;
  width: 180px;
`

const ScheduleTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    font-weight: 600;
  }

  td:first-child {
    font-weight: 500;
    width: 120px;
  }
`

const TimeSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  background-color: white;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`

const HelpText = styled.p`
  font-size: 14px;
  color: ${({theme}) => theme.colors.label};
  margin-top: 10px;
`
