import {
  CREATE_PHARMACY,
  TOGGLE_ADMIN_PAGE,
  FETCH_DRIVERS,
  FETCH_DRIVERS_SUCCESS,
  FETCH_STORES,
  FETCH_STORES_SUCCESS,
  ADMIN_FETCH_STORE,
  EDIT_ADMIN_FETCH_STORE,
  ADD_DRIVER,
  ADD_DRIVER_SUCCESS,
  DELETE_DRIVERS,
  DELETE_DRIVERS_SUCCESS,
  GENERATE_STORE_REPORTS,
  GENERATE_STORE_REPORTS_SUCCESS,
  GET_STORE_REPORTS_STATUS,
  GET_STORE_REPORTS_STATUS_SUCCESS,
  STOP_STORE_REPORTS_POLLING,
  CLEAR_STORE_REPORTS_QUEUE,
  CONNECT_QUICKBOOKS,
  QUICKBOOKS_CONNECTION_SUCCESS,
  HANDLE_QUICKBOOKS_CALLBACK,
  GENERATE_QB_INVOICES,
  GENERATE_QB_INVOICES_SUCCESS,
  GET_QB_INVOICES_STATUS,
  GET_QB_INVOICES_STATUS_SUCCESS,
  STOP_QB_INVOICES_POLLING,
  CLEAR_QB_INVOICES_QUEUE,
  GENERATE_EMAIL_QUEUE,
  GENERATE_EMAIL_QUEUE_SUCCESS,
  GET_EMAIL_QUEUE_STATUS,
  GET_EMAIL_QUEUE_STATUS_SUCCESS,
  STOP_EMAIL_QUEUE_POLLING,
  CLEAR_EMAIL_QUEUE,
  CLEAR_REDIS,
} from './types'

const createPharmacy = (pharmacy) => {
  return {
    type: CREATE_PHARMACY,
    payload: pharmacy,
  }
}

const toggleAdminPage = (tab) => {
  return {
    type: TOGGLE_ADMIN_PAGE,
    payload: tab,
  }
}

const fetchDrivers = (data) => {
  return {
    type: FETCH_DRIVERS,
    payload: data,
  }
}

const fetchDriversSuccess = (data) => {
  return {
    type: FETCH_DRIVERS_SUCCESS,
    payload: data,
  }
}

const fetchStores = (data) => {
  return {
    type: FETCH_STORES,
    payload: data,
  }
}

const fetchStoresSuccess = (data) => {
  return {
    type: FETCH_STORES_SUCCESS,
    payload: data,
  }
}

const adminFetchStore = (data) => {
  return {
    type: ADMIN_FETCH_STORE,
    payload: data,
  }
}
const editAdminFetchStore = (data) => {
  return {
    type: EDIT_ADMIN_FETCH_STORE,
    payload: data,
  }
}

const addDriver = (data) => ({
  type: ADD_DRIVER,
  payload: data,
})

const addDriverSuccess = (data) => ({
  type: ADD_DRIVER_SUCCESS,
  payload: data,
})

export const deleteDrivers = (ids) => ({
  type: DELETE_DRIVERS,
  payload: ids,
})

export const deleteDriversSuccess = (results) => ({
  type: DELETE_DRIVERS_SUCCESS,
  payload: results,
})

export const generateStoreReports = (storeIds, dateRange) => ({
  type: GENERATE_STORE_REPORTS,
  payload: {storeIds, dateRange},
})

export const generateStoreReportsSuccess = (data) => ({
  type: GENERATE_STORE_REPORTS_SUCCESS,
  payload: data,
})

const getStoreReportsStatus = (storeIds) => {
  return {
    type: GET_STORE_REPORTS_STATUS,
    payload: storeIds,
  }
}

export const getStoreReportsStatusesSuccess = (data) => ({
  type: GET_STORE_REPORTS_STATUS_SUCCESS,
  payload: data,
})

export const stopStoreReportsPolling = () => ({
  type: STOP_STORE_REPORTS_POLLING,
})

export const clearStoreReportsQueue = () => ({
  type: CLEAR_STORE_REPORTS_QUEUE,
})

export const connectQuickbooks = () => ({
  type: CONNECT_QUICKBOOKS,
})

export const quickbooksConnectionSuccess = (token) => ({
  type: QUICKBOOKS_CONNECTION_SUCCESS,
  payload: {token},
})

export const handleQuickbooksCallback = (code, realmId, state) => ({
  type: HANDLE_QUICKBOOKS_CALLBACK,
  payload: {code, realmId, state},
})

export const generateQBInvoices = (storeIds) => ({
  type: GENERATE_QB_INVOICES,
  payload: storeIds,
})

export const generateQBInvoicesSuccess = (jobIds) => ({
  type: GENERATE_QB_INVOICES_SUCCESS,
  payload: jobIds,
})

const getQBInvoicesStatus = (jobId) => ({
  type: GET_QB_INVOICES_STATUS,
  payload: jobId,
})

export const getQBInvoicesStatusSuccess = (status) => ({
  type: GET_QB_INVOICES_STATUS_SUCCESS,
  payload: status,
})

export const stopQBInvoicesPolling = () => ({
  type: STOP_QB_INVOICES_POLLING,
})

export const clearQBInvoicesQueue = () => ({
  type: CLEAR_QB_INVOICES_QUEUE,
})

export const generateEmailQueue = (storeIds) => ({
  type: GENERATE_EMAIL_QUEUE,
  payload: storeIds,
})

export const generateEmailQueueSuccess = (jobIds) => ({
  type: GENERATE_EMAIL_QUEUE_SUCCESS,
  payload: jobIds,
})

export const getEmailQueueStatus = (jobId) => ({
  type: GET_EMAIL_QUEUE_STATUS,
  payload: jobId,
})

export const getEmailQueueStatusSuccess = (status) => ({
  type: GET_EMAIL_QUEUE_STATUS_SUCCESS,
  payload: status,
})

export const stopEmailQueuePolling = () => ({
  type: STOP_EMAIL_QUEUE_POLLING,
})

export const clearEmailQueue = () => ({
  type: CLEAR_EMAIL_QUEUE,
})

export const clearRedis = () => ({
  type: CLEAR_REDIS,
})

export {
  createPharmacy,
  toggleAdminPage,
  fetchDrivers,
  fetchDriversSuccess,
  fetchStores,
  fetchStoresSuccess,
  adminFetchStore,
  editAdminFetchStore,
  addDriver,
  addDriverSuccess,
  getStoreReportsStatus,
  getQBInvoicesStatus,
}
