import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'
import {showError} from '../../redux/actions'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import AddIcon from '@material-ui/icons/Add'

import {
  getDelivery,
  deleteDelivery,
  clearCurrentDelivery,
  updateDelivery,
  deleteOrder,
  setOrders,
  addReturnsToDelivery,
  cancelDelivery,
  getOnHoldOrders,
  addOnHoldOrdersToDelivery,
  getAllCards,
} from '../../redux/actions'
import {generalFormatDate, formatDate} from '../../utils/dates'
import {formatName} from '../../utils/common'

import DatePicker from '../../components/DatePicker'
import Table from '../../components/Table'
import {MapViewButton, PrimaryButton} from '../../components/Button'
import PrescriptionTotalSection from '../../components/PrescriptionTotalSection'

import {
  Container,
  TopContainer,
  HeaderTypography,
  DeliveryDetails,
  LabelTextCombination,
  DeliveryDetailsContainer,
  DeliveryActions,
} from './styles'

import {
  Modal,
  DeleteOrderModal,
  ConfirmDeliveryModal,
  CameraModal,
  OrdersReturnedModal,
  ScriptRunnerCardLoadingModal,
  ScriptRunnerCardSuccessModal,
  MapViewModal,
  VerifyDeliveryModal,
  OnHoldOrdersModal,
} from '../../components/Modal'
import BackButton from '../../components/BackButton'
import {mapOrderPlaces} from '../../utils/maps'
import {Button} from '@material-ui/core'
import {DeleteIcon} from '../../components/Icons'
import {CustomIconButton} from '../../components/CustomIconButton'
import DeleteDelivery from '../../components/Modal/DeleteDeliveryModal'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginTop: '1.6rem',
    marginBottom: '1.2rem',
  },
  topContainer: {
    textAlign: 'center',
    marginBottom: '40px !important',
  },
  label: {
    fontWeight: 500,
  },
  addressText: {
    marginTop: '26px',
  },
  addOrderButton: {
    alignSelf: 'center',
    width: '16.5rem',
  },
  nextButton: {
    maxWidth: '14rem',
    margin: '2.8rem 0 2.8rem 0',
    alignSelf: 'flex-end',
  },
  addReturnButton: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    alignSelf: 'center',
    transition: 'none',
    width: '18rem',
    marginRight: '1.2rem',
    textTransform: 'capitalize',
    '&[disabled]': {
      color: theme.buttons.disabled.label,
    },
  },
}))

// NOTE: AddNewDelivery and DeliveryStatus components are very similar
// Changes likely needs to be applied to both
// Or ideally refactored to dedupe these components 🙄
const Delivery = ({
  deliveryDate = new Date(),
  rootPath,
  delivery = {},
  orders = [],
  clearCurrentDelivery,
  updateDeliveryDate,
  deleteOrder,
  setOrders,
  currentReturns,
  addReturnsToDelivery,
  cancelDelivery,
  getDelivery,
  addOnHoldOrdersToDelivery,
  getAllCards,
  cardsFetched,
  deleteDelivery,
  showError,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const {deliveryId} = useParams()
  const [date, setSelectedDate] = useState(new Date(deliveryDate))
  const [displayDate, setDisplayDate] = useState(formatDate(deliveryDate))
  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [orderIdSelected, setOrderIdSelected] = useState()
  const [sortConfig, setSortConfig] = useState({
    property: 'orderId',
    direction: 'desc'
  });

  useEffect(() => {
    if (!cardsFetched) getAllCards() // Fetch cards when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //TODO: this is causing max stack errors - resolve this ASAP
    const formatedDate = generalFormatDate(deliveryDate)
    setSelectedDate(formatedDate)
    setDisplayDate(formatDate(formatedDate))
    if ((currentReturns?.length ?? 0) > 0 && orders.length === 0) {
      setOrdersReturnedModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate, orders])

  const addReturnedOrders = (data) => {
    addReturnsToDelivery({deliveryId: deliveryId, orders: data})
    handleModalClose()
  }

  const addOnHoldOrders = (data) => {
    if (data.length > 0) {
      addOnHoldOrdersToDelivery({deliveryId: deliveryId, orders: data})
    }
    handleModalClose()
  }

  async function deleteOrderFromModal() {
    deleteOrder({
      orderId: orderIdSelected,
      callback: () => {
        getDelivery(deliveryId)
        handleModalClose()
      },
    })
  }

  const onScriptRunnerCardConfirm = () => {
    deleteOrder(orderIdSelected)
    handleModalOpen({
      modalContent: 'ScriptRunnerCardSuccessModal',
    })
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    setDisplayDate(formatDate(newDate))
    updateDeliveryDate({delivery: {deliveryDate: newDate}, deliveryId})
  }

  const handleModalOpen = ({modalContent}) => {
    setModal({
      isOpen: true,
      modalContent,
    })
  }

  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
  }

  const confirmDelivery = () => {
    updateDeliveryDate({
      delivery: {deliveryStatus: 'PICKUP_READY', deliveryDate: date},
      deliveryId,
      successCb: setConfirmationModal,
    })
  }

  const verifyOrders = () => {
    if (orders.some((order) => !order.detail.length)) {
      showError({
        message:
          'Please add TX numbers / Barcodes to all orders before the delivery can be submitted',
      })
    } else {
      verifyDelivery()
    }
  }

  const verifyDelivery = () => {
    setModal({
      isOpen: true,
      modalContent: 'VerifyDeliveryModal',
    })
  }

  const setOnHoldOrdersModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'OnHoldOrdersModal',
    })
  }

  const setConfirmationModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'ConfirmDeliveryModal',
    })
  }

  const setDeleteOrderModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'DeleteOrderModal',
    })
  }

  const setOrdersReturnedModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'OrdersReturnedModal',
    })
  }

  const setMapViewModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'MapViewModal',
    })
  }

  const handleDeleteDelivery = () => {
    setModal({
      isOpen: true,
      modalContent: 'DeleteDeliveryModal',
    })
  }

  const redirectToHome = () => {
    if (orders?.length === 0) {
      cancelDelivery(history, deliveryId)
      return
    }
    history.push(rootPath)
    clearCurrentDelivery()
  }

  const getSortableValue = (order, property) => {
    if (property === 'customerName') {
      const formattedName = formatName(order.customerName || '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ');
      return formattedName;
    }
    if (property === 'customerPhone') {
      return (order.customerPhone || '').replace(/\D/g, '');
    }
    if (property === 'orderId') {
      return order.orderId;
    }
    return String(get(order, property, '')).toLowerCase();
  };

  const getSortedOrders = () => {
    return orderBy(
      orders, 
      [order => getSortableValue(order, sortConfig.property)], 
      [sortConfig.direction]
    );
  };

  const sortedOrders = getSortedOrders();

  const sortOrders = (property, direction) => {
    if (property === sortConfig.property) {
      if (sortConfig.direction === 'asc') {
        setSortConfig({ property, direction: 'desc' });
        const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['desc']);
        setOrders(sorted);
      } 
      else {
        setSortConfig({ property, direction: 'asc' });
        const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['asc']);
        setOrders(sorted);
      }
    } 
    else {
      setSortConfig({ property, direction: 'asc' });
      const sorted = orderBy(orders, [order => getSortableValue(order, property)], ['asc']);
      setOrders(sorted);
    }
  };

  const containsOrders = () => !!orders.length

  const totalPrice = orders
    .reduce((prev, next) => prev + parseFloat(next.paymentTotal), 0)
    .toFixed(2)

  // TODO: architecture review look at if switch case is the best way to toggle modal content
  const displayModal = (modalType) => {
    switch (modalType) {
      case 'ConfirmDeliveryModal':
        return (
          <ConfirmDeliveryModal
            confirmModalIntent={redirectToHome}
            date={date}
            location={delivery.storeName}
          />
        )
      case 'DeleteOrderModal':
        return (
          <DeleteOrderModal
            primaryAction={deleteOrderFromModal}
            secondaryAction={handleModalClose}
          />
        )
      case 'OrdersReturnedModal':
        return (
          <OrdersReturnedModal
            handleClose={() => addReturnedOrders([])}
            orders={currentReturns}
            onSubmit={(data) => addReturnedOrders(data)}
          />
        )
      case 'ScriptRunnerCardLoadingModalRefund':
        return (
          <ScriptRunnerCardLoadingModal
            handleClose={handleModalClose}
            onClick={onScriptRunnerCardConfirm}
            isCharge={false}
          />
        )
      case 'ScriptRunnerCardSuccessModal':
        return (
          <ScriptRunnerCardSuccessModal
            handleDone={() => history.go(0)}
            date={formatDate(
              orders.find(
                (order) => order.orderId?.toString() === orderIdSelected,
              )?.latestChargeDate,
            )}
            total={
              orders.find(
                (order) => order.orderId?.toString() === orderIdSelected,
              )?.paymentTotal
            }
            isRefund={true}
          />
        )
      case 'MapViewModal':
        if (!!orders.length) {
          const places = orders.map(mapOrderPlaces)
          return (
            <MapViewModal
              places={places}
              handleCancelAction={handleModalClose}
            />
          )
        }
        return <></>
      case 'VerifyDeliveryModal':
        const places = orders.map(mapOrderPlaces)
        return (
          <VerifyDeliveryModal
            confirmModalIntent={confirmDelivery}
            cancelModal={handleModalClose}
            date={date}
            location={delivery.storeName}
            places={places}
          />
        )
      case 'OnHoldOrdersModal':
        return (
          <OnHoldOrdersModal
            handleClose={() => handleModalClose()}
            onSubmit={(data) => addOnHoldOrders(data)}
          />
        )
      case 'DeleteDeliveryModal':
        return (
          <DeleteDelivery
            primaryAction={() => deleteDelivery(history, deliveryId)}
            secondaryAction={() => handleModalClose()}
          />
        )
      default:
        break
    }
  }
  return (
    <>
      <Container>
        <TopContainer className={classes.topContainer}>
          <BackButton click={redirectToHome} />
          <div style={{display: 'flex'}}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <HeaderTypography variant="h1">
                Start New Delivery
              </HeaderTypography>
              <div>{displayDate}</div>
            </div>
            {delivery.deliveryStatus === 'DRAFT' && (
              <CustomIconButton
                style={{
                  width: '40px',
                  marginTop: '-6px',
                  alignSelf: 'flex-start',
                }}
                id={`delete-delivery-${deliveryId}-button`}
                onClick={() => handleDeleteDelivery()}>
                <DeleteIcon />
              </CustomIconButton>
            )}
          </div>
          {!!orders.length ? (
            <MapViewButton
              onClick={setMapViewModal}
              disabled={orders.some((order) => order.latitude === null)}
            />
          ) : (
            <div />
          )}
        </TopContainer>

        <DeliveryDetailsContainer>
          <DeliveryDetails>
            <DatePicker
              label="Select Delivery Date"
              name="deliveryDate"
              date={date}
              value={date}
              onChange={handleDateChange}
              autoOk
            />
            <LabelTextCombination>
              <FormLabel className={classes.label}>Pharmacy location</FormLabel>
              <Typography variant="body1" className={classes.addressText}>
                {delivery.storeName}
              </Typography>
            </LabelTextCombination>
          </DeliveryDetails>
          <DeliveryActions>
            <Button
              id="on-hold-orders-button"
              type="button"
              className={classes.addReturnButton}
              startIcon={<AddIcon />}
              onClick={setOnHoldOrdersModal}>
              Add Previous Return
            </Button>
            <PrimaryButton
              id="add-prescription-button"
              className={classes.addOrderButton}
              startIcon={<AddIcon />}
              onClick={() => setIsCameraOpen(true)}>
              Add an Order
            </PrimaryButton>
          </DeliveryActions>
        </DeliveryDetailsContainer>
        <Table
          rows={sortedOrders}
          keyProp="orderId"
          headers={[
            {label: 'Name', property: 'customerName', onClick: sortOrders},
            {label: 'Address'},
            {label: 'Phone', property: 'customerPhone', onClick: sortOrders},
            {label: 'TX Number / Price'},
            {label: 'Customer Payment'},
            {label: 'Delivery Status'},
            {label: 'Actions'},
          ]}
          properties={[
            'customerName',
            'customerAddress',
            'customerPhone',
            'detail',
            'paymentType',
            'orderStatus',
            'actions',
          ]}
          primaryAction={(orderId, isEditable) => {
            history.push({
              pathname: `${deliveryId}/add-new-prescription/${orderId}`,
              state: {isEditable},
            })
          }}
          secondaryAction={(orderId) => {
            setOrderIdSelected(orderId)
            setDeleteOrderModal()
          }}></Table>
        {!!orders.length && (
          <PrescriptionTotalSection
            orders={orders}
            delivery={delivery}
            totalPrice={totalPrice}
          />
        )}
        <PrimaryButton
          id="schedule-delivery-button"
          type="button"
          disabled={!containsOrders() || totalPrice < 0}
          className={classes.nextButton}
          onClick={verifyOrders}>
          Schedule Delivery
        </PrimaryButton>
      </Container>
      <CameraModal
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
        {displayModal(modal.modalContent)}
      </Modal>
    </>
  )
}
const mapStateToProps = ({user = {}, deliveries = [], cards = {}}) => {
  return {
    delivery: deliveries.selectedDelivery,
    deliveryDate: get(deliveries, 'selectedDelivery.deliveryDate'),
    orders: get(deliveries, 'selectedDelivery.orders', []),
    token: user.token,
    currentReturns: deliveries.currentReturns,
    onHoldOrders: deliveries.onHoldOrders,
    cards: cards.customers,
    cardsFetched: cards.cardsFetched,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
  deleteDelivery: (history, deliveryId) =>
    dispatch(deleteDelivery(history, deliveryId)),
  clearCurrentDelivery: () => dispatch(clearCurrentDelivery()),
  updateDeliveryDate: (payload) => dispatch(updateDelivery(payload)),
  deleteOrder: (orderId) => dispatch(deleteOrder({orderId})),
  setOrders: (orders) => dispatch(setOrders(orders)),
  addReturnsToDelivery: (data) => dispatch(addReturnsToDelivery({data})),
  addOnHoldOrdersToDelivery: (data) =>
    dispatch(addOnHoldOrdersToDelivery({data})),
  cancelDelivery: (history, deliveryid) =>
    dispatch(cancelDelivery(history, deliveryid)),
  getOnHoldOrders: (returnDate) => dispatch(getOnHoldOrders(returnDate)),
  getAllCards: () => dispatch(getAllCards()),
  showError: (message) => dispatch(showError(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Delivery)
