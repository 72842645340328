import React, {useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react'
import get from 'lodash.get'
import Intercom from '@intercom/messenger-js-sdk'
import Navbar from '../../containers/Navbar'
import ToastMessage from '../ToastMessage'

import {
  updateUser,
  saveToken,
  setTokenExpiry,
  logoutUser,
  getIntercomHash,
} from '../../redux/actions'
import {UNAUTHORIZED_CODE_ERROR} from '../../utils/constants'

function PrivateRoute({
  component,
  dispatchUpdateUser,
  dispatchSaveToken,
  dispatchTokenExpiryTime,
  dispatchLogoutUser,
  dispatchGetIntercomHash,
  tokenExpiryTime,
  intercomHash,
  error,
  ...rest
}) {
  const {isLoading, isAuthenticated, user, getIdTokenClaims, logout} =
    useAuth0()

  const getToken = useCallback(async () => {
    try {
      const accessToken = await getIdTokenClaims()
      return accessToken
    } catch (err) {
      console.log('err', err)
    }
  }, [getIdTokenClaims])

  useEffect(() => {
    const code = get(error, 'code')
    const currentTime = Math.trunc(new Date().getTime() / 1000)

    if (currentTime > tokenExpiryTime && code === UNAUTHORIZED_CODE_ERROR) {
      dispatchLogoutUser()
      logout({returnTo: window.location.origin})
    }

    if (!isLoading && isAuthenticated) {
      getToken().then((token) => {
        dispatchUpdateUser(user)
        dispatchSaveToken(token?.__raw)
        dispatchTokenExpiryTime(token?.exp)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error])

  useEffect(() => {
    if (!user) return

    // dispatchGetIntercomHash(user.email)
  }, [user])

  // useEffect(() => {
  //   if (!intercomHash || !user) return

  //   Intercom({
  //     app_id: 'y9bbreur',
  //     user_id: user.sub,
  //     name: user.name,
  //     email: user.email,
  //     user_hash: intercomHash,
  //   })
  // }, [intercomHash, user])

  if (isLoading) return 'Loading...'

  return (
    <>
      <Navbar />
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => 'isLoading',
        })}
        {...rest}
      />
      <ToastMessage />
    </>
  )
}

const mapStateToProps = ({app, user = {}}) => {
  return {
    tokenExpiryTime: user.expiresAt,
    intercomHash: user.intercomHash,
    error: app.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUser: (userInfo) => dispatch(updateUser(userInfo)),
  dispatchSaveToken: (token) => dispatch(saveToken(token)),
  dispatchTokenExpiryTime: (expiresAt) => dispatch(setTokenExpiry(expiresAt)),
  dispatchLogoutUser: () => dispatch(logoutUser()),
  dispatchGetIntercomHash: (email) => dispatch(getIntercomHash(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
